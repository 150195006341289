<template>
    <div>
        <center>
            <h4>Tambah Chat Widget</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addChatWidget" enctype="multipart/form-data">
            <center><b>Detail Widget</b></center>
            <br>
            <label for="">Judul Chat Widget</label>
            <input type="text" class="form-control" v-model="form.title" placeholder="Butuh Bantuan ?" required>
            <br>
            <label for="">Deskripsi Chat Widget</label>
            <input type="text" class="form-control" v-model="form.description" placeholder="Chat disini ya kak" required>
            <br>
            <label for="">Domain</label>
            <input type="text" class="form-control" v-model="form.domain" placeholder="www.example.com" required>
            <br>
            <label for="">Tipe</label>
            <select v-model="form.type" class="form-control">
                <option value="" selected disabled>== PILIH TIPE ==</option>
                <option value="direct">DIRECT</option>
                <option value="lander">LANDER</option>
            </select>
            <br>
            <div v-if="form.type == 'lander'">
                <div class="card">
                    <div class="card-body">
                        <center><b>Pengaturan Lander</b></center>
                        <br>
                        <label for="">Tracking Option</label>
                        <select v-model="form.tracking_option" class="form-control">
                            <option value="" selected disabled>== PILIH TRACKING OPTION ==</option>
                            <option value="fb_pixel">Facebook Pixel</option>
                            <option value="google_tag">Google Tag Manager</option>
                        </select>
                        <div v-if="form.tracking_option == 'fb_pixel'">
                            <br>
                            <label for="">Pixel ID</label>
                            <input type="text" class="form-control" v-model="form.fb_track">
                        </div>
                        <div v-else-if="form.tracking_option == 'google_tag'">
                            <br>
                            <label for="">Google Tag ID</label>
                            <input type="text" class="form-control" v-model="form.google_track">
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <center><b>Style Widget</b></center>
            <br>
            <label for="">Gaya Widget</label>
            <select v-model="form.widget_style" class="form-control">
                <option value="1">Judul + Deskripsi + Logo Whatsapp</option>
                <option value="2">Logo Whatsapp</option>
            </select>
            <br>
            <label for="">Posisi Widget</label>
            <select v-model="form.widget_position" class="form-control">
                <option value="left">Kiri Layar</option>
                <option value="right">Kanan Layar</option>
            </select>
            <br>
            <label for="">Warna Widget</label>
            <input type="color" class="form-control" v-model="form.widget_bg_color" required>
            <br>
            <label for="">Warna Font</label>
            <input type="color" class="form-control" v-model="form.widget_text_color" required>
            <br>
            <hr>
            <center><b>List Customer Service</b></center>
            <br>
            <button type="button" v-on:click="addCS" class="btn btn-primary btn-sm btn-block">Tambah CS</button>
            <div v-for="(cs_list, index) in form.cs" :key="index">
                <br>
                <label for="">Pilih Customer Service {{ index + 1 }}</label>
                <br>
                <select v-model="form.cs[index]" class="form-control" style="max-width: 85%;display: inline-block;">
                    <option :value="{}" selected disabled>== PILIH CUSTOMER SERVICE ==</option>
                    <option v-for="customer_service in cs" v-bind:key="customer_service.id" :value="customer_service">{{ customer_service.name }} ({{ customer_service.phone }})</option>
                </select>
                <button type="button" v-on:click="deleteCS(index)" class="btn btn-sm btn-danger btn-block" style="max-width: 15%;display: inline-block;">Hapus</button>
            </div>
           
            <hr>
            <center><b>Opsi Lainnya</b></center>
            <br>
            <label for="">Buka Otomatis Chat Widget (detik)</label>
            <input type="number" v-model="form.auto_open" class="form-control" required>
            <br>
            <i>Isi dengan 0 jika tidak ingin membuka chat widget secara otomatis</i>
            <br>
            <br>
            <label for="">Tipe Mulai Chat</label>
            <select v-model="form.starting_chat" class="form-control" required>
                <option value="" selected disabled>== PILIH TIPE MULAI CHAT ==</option>
                <option value="reply">RELPY MODE</option>
                <option value="direct">DIRECT MODE</option>
            </select>
            <div v-if="form.starting_chat == 'direct'">
                <br>
                <label for="">Pesan Mulai Chat</label>
                <CustomTextArea v-model="form.message"></CustomTextArea>
            </div>
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    //import moment from 'moment'
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        props: ['cs'],
        components: {
            CustomTextArea
        },
        data() {
            return {
                form: {
                    title: "",
                    description: "",
                    domain: "",
                    type: "",
                    widget_style: "1",
                    widget_position: "right",
                    widget_bg_color: "#3ebfa5",
                    widget_text_color: "#ffffff",
                    auto_open: "0",
                    cs: [],
                    tracking_option: "",
                    fb_track: "",
                    google_track: "",
                    starting_chat: "",
                    message: "",
                },
            };
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async addCS(){
                this.form.cs.push({})
            },
            async deleteCS(index){
                this.form.cs.splice(index, 1)
            },
            async addChatWidget(){
                try{
                    let create_chat_widget = await this.$store.dispatch('chat_widget_create', this.form)
                    this.$toast.success(create_chat_widget.data.message)
                    this.$root.$emit("toggle_modal", "new_chat_widget")
                    this.$root.$emit("refresh_table", true)                
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    hr{
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>