<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Chat Widget
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('new_chat_widget')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Chat Widget
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="chatWidgetQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas chat widget maksimal anda adalah <b>Unlimited</b> chat widget</p>
                </div>
            </div>
            <div v-else-if="chatWidgetQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas chat widget maksimal anda adalah <b>{{ chatWidgetQuota }}</b> chat widget</p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div class="alert-content">
                    <p>Batas chat widget maksimal anda adalah <b>{{ chatWidgetQuota }}</b> chat widget</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <ChatWidgetTable />
                    </div>
                </div>
            </div>
            <modal name="new_chat_widget" :height="'auto'" :adaptive="true" :scrollable="true" style="overflow-y: auto;">
                <div style="margin: 20px">
                    <AddChatWidget :cs="cs" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import ChatWidgetTable from "@/components/tables/ChatWidget.vue";
    import AddChatWidget from "@/components/forms/chat_widget/Add.vue";

    export default {
        components: {
            ChatWidgetTable,
            AddChatWidget,
        },
        data() {
            return {
                cs: [],
                exportJson: [],
                chatWidgetQuota: 0,
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
            this.chatWidgetQuota = this.$store.state.auth.package.max_chat_widget
            this.getCS()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getCS(param = {}) {
                let res = await this.$store.dispatch("customer_service_dropdown_list", param);
                this.cs = res.data.data.customer_services
            },
        },
    };
</script>